@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #fff;
}
